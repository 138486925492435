var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vx-card',{staticClass:"cardx",attrs:{"actionable":"","title":"Delays(USP)"}},[_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('datepicker',{staticStyle:{"z-index":"1000"},attrs:{"placeholder":"Select Date"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('v-select',{staticStyle:{"width":"240px","z-index":"1000"},attrs:{"options":_vm.sub_teams},model:{value:(_vm.selected_sub_team),callback:function ($$v) {_vm.selected_sub_team=$$v},expression:"selected_sub_team"}})],1)],1),_c('vs-table',{staticStyle:{"margin-top":"3%","border-top":"1px solid","border-left":"1px solid","color":"black"},attrs:{"data":_vm.tableRows},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,style:(tr.style)},[_c('vs-td',[_vm._v(_vm._s(tr.overall))]),_c('vs-td',[_vm._v(_vm._s(tr.level))]),_c('vs-td',{staticStyle:{"border":"1px solid"}},[_c('div',{on:{"click":function($event){return _vm.preFlightFetch(
                   tr.level === '' ? tr.overall : tr.level,
                   _vm.selected_sub_team,
                   'allocated'
                 )}}},[_vm._v("\n               "+_vm._s(tr.sub_team.allocated)+"\n             ")])]),_c('vs-td',{staticStyle:{"border":"1px solid"}},[_c('div',{on:{"click":function($event){return _vm.preFlightFetch(
                   tr.level === '' ? tr.overall : tr.level,
                   _vm.selected_sub_team,
                   'delays'
                 )}}},[_vm._v("\n               "+_vm._s(tr.sub_team.delays)+"\n             ")])]),_vm._l((tr.dynamicSpoc),function(spoc,spocIndex){return _c('vs-td',{key:spocIndex,staticStyle:{"border":"1px solid"}},[_c('div',{on:{"click":function($event){return _vm.preFlightFetch(
                   tr.level === '' ? tr.overall : tr.level,
                   [tr, spocIndex],
                   data[0].dynamicSpoc[spocIndex]
                 )}}},[_vm._v("\n               "+_vm._s(spoc)+"\n             ")])])})],2)})}}])},[_c('template',{slot:"thead"},_vm._l((_vm.tableHeaders),function(head,index){return _c('vs-th',{key:index,style:({ borderRight: '1px solid' }),attrs:{"colspan":head.colspan}},[_vm._v(_vm._s(head.header))])}),1)],2)],1),_c('delays-m-acc-popup')],1)}
var staticRenderFns = []

export { render, staticRenderFns }