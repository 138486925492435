<template>
  <div>
    <vx-card actionable class="cardx" title="Delays(USP)">
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <datepicker
            placeholder="Select Date"
            v-model="dateRange"
            style="z-index: 1000"
          ></datepicker>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            :options="sub_teams"
            v-model="selected_sub_team"
            style="width: 240px; z-index: 1000"
          ></v-select>
        </vs-col>
      </vs-row>
 <vs-table
        :data="tableRows"
        style="margin-top: 3%; border-top: 1px solid; border-left: 1px solid; color: black"
      >
        <template slot="thead">
          <vs-th
            :key="index"
            v-for="(head, index) in tableHeaders"
            :colspan="head.colspan"
            :style="{ borderRight: '1px solid' }"
            >{{ head.header }}</vs-th
          >
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" :style="tr.style">
            <vs-td>{{ tr.overall }}</vs-td>
            <vs-td >{{ tr.level }}</vs-td>
            <vs-td style="border: 1px solid">
              <div
                @click="
                  preFlightFetch(
                    tr.level === '' ? tr.overall : tr.level,
                    selected_sub_team,
                    'allocated'
                  )
                "
              >
                {{ tr.sub_team.allocated }}
              </div>
            </vs-td>
            <!-- <vs-td style="border: 1px solid">
            <div
              @click="preFlightFetch(tr.level === '' ? tr.overall : tr.level, selected_city, 'contacted')"
            >{{tr.city.contacted}}</div>
            </vs-td>-->
            <vs-td style="border: 1px solid">
              <div
                @click="
                  preFlightFetch(
                    tr.level === '' ? tr.overall : tr.level,
                    selected_sub_team,
                    'delays'
                  )
                "
              >
                {{ tr.sub_team.delays }}
              </div>
            </vs-td>
            <vs-td
              v-for="(spoc, spocIndex) in tr.dynamicSpoc"
              :key="spocIndex"
              style="border: 1px solid"
            >
              <div
                @click="
                  preFlightFetch(
                    tr.level === '' ? tr.overall : tr.level,
                    [tr, spocIndex],
                    data[0].dynamicSpoc[spocIndex]
                  )
                "
              >
                {{ spoc }}
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <!-- <delays-by-m-acc-level :open = "open"></delays-by-m-acc-level> -->
    <delays-m-acc-popup></delays-m-acc-popup>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import constants from "../../constants.json";
import axios from "axios";
import EventBus from "../components/eventbus";
import DelaysMAccPopup from "./DelaysMAccPopup.vue"
export default {
  mounted() {
    this.getSpocs();
  },
  components: {
    Datepicker,
    "v-select": vSelect,
    DelaysMAccPopup,
  },
  watch: {
    dateRange() {
      this.getMAccLevelsCount();
    },
  },
  data() {
    return {
      selected_sub_team: "USP",
      sub_teams: ["USP"],
      dateRange: new Date(),
      open:false,
      macc_id: [],
      tableRows: [],
      tableHeaders: [],
       tablerowConstant: [
        {
          style: { borderTop: "1px solid", borderBottom: "1px solid" },
          overall: "",
          level: ""
        },
        {
          style: { borderBottom: "1px solid", background: "#FFCCCC" },
          overall: "Total",
          level: ""
        },
        {
          style: { background: "#ccffcc" },
          level: "U0",
          rowspan: 4,
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U1",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U2",
          type: "P1"
        },
         {
          style: { background: "#ccffcc" },
          level: "U3",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U3+",
          type: "P"
        },
        {
          style: { background: "#ccffcc" },
          level: "U3++",
          type: "P"
        },
        {
          style: { background: "#ccffcc" },
          level: "U3-",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U4",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U4-",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U5",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U6",
          type: "P1"
        },
         {
          style: { background: "#ccffcc" },
          level: "U7",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U9",
          type: "P1"
        },
        {
          style: { borderTop: "1px solid", background: "#FFCCCC", borderBottom: "1px solid" },
          overall: "Total",
          level: ""
        },
      ],
      macc_details:[],
    };
  },
  methods: {
    getSpocs() {
      let url = `${constants.SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          let macc_data = response.data.spocs;
          this.macc_details = response.data.spocs;
          this.macc_id = [];
          for (let i = 0; i < macc_data.length; i++) {
            const element = macc_data[i];
            if (element.sub_team == "MAcc") {
              this.macc_id.push(element.id);
            }
          }
          console.log("id", this.macc_id.join());
          this.getMAccLevelsCount();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    getMAccLevelsCount() {
      // this.table_data =[];
      let obj = {
        selected_date: this.datatoTimestamp(this.dateRange),
        spoc_ids: this.macc_id.join(),
      };
      console.log("obj", obj);
      let url = `${constants.MILES_GOOGLE_INVITE}getDelaysByLevelExEd`;
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // this.table_data = response.data.data
          // console.log("Macc counts", this.table_data);
          this.generateDataTable(response.data.data);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    generateDataTable(response) {
      this.tableRows = this.tablerowConstant;
      this.generateTableHeaders(response);
      this.generateTableRows(response);
    },
     generateTableHeaders(response) {
      // console.log("asd", spoc_names);
      console.log("delays  by sub teams", response);
      this.tableHeaders = [];
      this.tableHeaders.push({
        header: "OverAll",
        colspan: 1,
        fixed: true
      });
      this.tableHeaders.push({
        header: "Level",
        colspan: 1,
        fixed: true
      });
      let sub_team = this.selected_sub_team;
      this.tableHeaders.push({
        header: sub_team,
        colspan: 2
      });
      response.forEach(cityData => {
        let obj = {
          header: cityData.spoc_name,
          colspan: 2
        };
        this.tableHeaders.push(obj);
      });
    },
   generateTableRows(response) {
      this.tableRows[0].dynamicSpoc = [];
      this.tableRows[0].sub_team = {
        allocated: "Allocated",
        delays: "Delays"
      };
      this.tableRows.forEach((row, index) => {
        if (index !== 0) {
          row.dynamicSpoc = [];
          row.sub_team = {
            allocated: 0,
            delays: 0
          };
        }
      });
      response.forEach(() => {
        let allocated = 0;
        let delays = 0;
        this.tableRows[0].dynamicSpoc.push("Allocated");
        this.tableRows[0].dynamicSpoc.push("Delays");
        this.tableRows.forEach((row, index) => {
          if (index !== 0) {
            row.dynamicSpoc.push(allocated);
            row.dynamicSpoc.push(delays);
          }
        });
      });
      this.InitialCalculation(response);
    },
    InitialCalculation(response) {
      console.log("InitialCalculation response",response);
      this.tableRows.forEach((row, index) => {
        if (index > 1 && index !== this.tableRows.length - 1) {
          let cityallocated = 0;
          // let citycontacted = 0;
          let citydelays = 0;
          row.dynamicSpoc = [];
          response.forEach(spoc => {
            for (var key in spoc) {
              if (spoc.hasOwnProperty(key)) {
                if (key !== "NA" && key !== "spoc_id" && key !== "spoc_name") {
                  for (var key2 in spoc[key]) {
                    if (spoc[key].hasOwnProperty(key2)) {
                      if (key2 === row.level) {
                        cityallocated =
                          cityallocated + spoc[key][key2].allocated;
                        citydelays = citydelays + spoc[key][key2].delays;
                        row.dynamicSpoc.push(spoc[key][key2].allocated);
                        row.dynamicSpoc.push(spoc[key][key2].delays);
                      }
                    }
                  }
                }
              }
            }
          });
          row.sub_team.allocated = cityallocated;
          row.sub_team.delays = citydelays;
        }
      });
      let totalcityallocated = 0;
      let totalcitydelays = 0;
      let dynamicarr = [];
      // let naallocated = 0;
      // let nadelays = 0;
      // let nadynamic = [];
      // response.forEach(spoc => {
      //   naallocated = naallocated + spoc.NA.allocated;
      //   nadelays = nadelays + spoc.NA.delays;
      //   nadynamic.push(spoc.NA.allocated);
      //   nadynamic.push(spoc.NA.delays);
      // });
      // this.tableRows[this.tableRows.length - 1].city.allocated = naallocated;
      // this.tableRows[this.tableRows.length - 1].city.delays = nadelays;
      // this.tableRows[this.tableRows.length - 1].dynamicSpoc = nadynamic;
      this.tableRows.forEach((row, index) => {
        if (index === 1) {
          dynamicarr = row.dynamicSpoc;
        } else if (index > 1 ) {
          totalcityallocated = totalcityallocated + row.sub_team.allocated;
          totalcitydelays = totalcitydelays + row.sub_team.delays;
          // totalcityallocated = row.sub_team.allocated;
          // totalcitydelays = row.sub_team.delays;
          // console.log(row.dynamicSpoc);
          row.dynamicSpoc.forEach((spocStat, index2) => {
            // dynamicarr[index2] =
            //   parseInt(dynamicarr[index2]) + parseInt(spocStat);
            dynamicarr[index2] = parseInt(spocStat);
          });
        }
      });
      this.tableRows[1].sub_team.allocated = totalcityallocated;
      
      this.tableRows[1].sub_team.delays = totalcitydelays;
      this.tableRows[1].dynamicSpoc = dynamicarr;
      this.calculateM7(response);
      this.calculateTotals();
      let last_row = this.tableRows.length - 1 ; 
      this.tableRows[last_row].sub_team.allocated = totalcityallocated;
      this.tableRows[last_row].sub_team.delays = totalcitydelays;
      this.tableRows[last_row].sub_team.dynamicSpoc = dynamicarr;
      this.$vs.loading.close();
    },
    calculateM7(response) {
      let totalcityallocated = 0;
      let totalcitydelays = 0;
      let dynamicarr = [];
      console.log(response)
      // let naallocated = 0;
      // let nadelays = 0;
      // let nadynamic = [];
      // response.forEach(spoc => {
      //   naallocated = naallocated + spoc.M7.allocated;
      //   nadelays = nadelays + spoc.M7.delays;
      //   nadynamic.push(spoc.M7.allocated);
      //   nadynamic.push(spoc.M7.delays);
      // });
      // this.tableRows[this.tableRows.length - 2].sub_team.allocated = naallocated;
      // this.tableRows[this.tableRows.length - 2].sub_team.delays = nadelays;
      // this.tableRows[this.tableRows.length - 2].dynamicSpoc = nadynamic;
      this.tableRows.forEach((row, index) => {
        if (index === 1) {
          dynamicarr = row.dynamicSpoc;
        } else if (index > 1) {
          totalcityallocated = totalcityallocated + row.sub_team.allocated;
          totalcitydelays = totalcitydelays + row.sub_team.delays;
          row.dynamicSpoc.forEach((spocStat, index2) => {
            dynamicarr[index2] =
              parseInt(dynamicarr[index2]) + parseInt(spocStat);
          });
        }
      });
      this.tableRows[1].sub_team.allocated = totalcityallocated;
      this.tableRows[1].sub_team.delays = totalcitydelays;
      console.log("fhakjkasfjl",totalcityallocated)
      this.tableRows[1].dynamicSpoc = dynamicarr;
      let last_row = this.tableRows.length - 1 ; 
      this.tableRows[last_row].sub_team.allocated = totalcityallocated;
      this.tableRows[last_row].sub_team.delays = totalcitydelays;
      this.tableRows[last_row].dynamicSpoc = dynamicarr;

    },
    calculateTotals() {
      let parts = ["P1", "P2", "P3"];
      parts.forEach(eachPart => {
        let cityAllocated = 0;
        let cityDelays = 0;
        let dynamicSpocValues = [];
        this.tableRows[0].dynamicSpoc.forEach(() => {
          dynamicSpocValues.push(0);
        });
        this.tableRows.forEach(tableRow => {
          if (tableRow.type === eachPart) {
            cityAllocated =
              parseInt(cityAllocated) + parseInt(tableRow.sub_team.allocated);
            cityDelays = parseInt(cityDelays) + parseInt(tableRow.sub_team.delays);
            tableRow.dynamicSpoc.forEach((value, index) => {
              dynamicSpocValues[index] =
                parseInt(dynamicSpocValues[index]) + parseInt(value);
            });
          } else if (tableRow.type === `${eachPart} Total`) {
            tableRow.sub_team.allocated = cityAllocated;
            tableRow.sub_team.delays = cityDelays;
            tableRow.dynamicSpoc = dynamicSpocValues;
          }
        });
      });
    },
    mapSearchSpoc(placement) {
      let obj = placement[0];
      let objindex = placement[1];
      let objlength = obj.dynamicSpoc.length / 2;
      let mapObj = [];
      for (let i = 1; i <= objlength; i++) {
        mapObj.push({
          sequence: i,
          data: []
        });
      }
      let count = 0;
      let map = 0;
      let mapname = 0;
      obj.dynamicSpoc.forEach((spocstat, index) => {
        if (count === 2) {
          count = 0;
          map = map + 1;
        }
        mapObj[map].data.push(spocstat);
        if (index === objindex) {
          mapname = map;
        }
        count = count + 1;
      });
      let spoc = this.foundspoc(mapname);
      return spoc;
    },
    foundspoc(spocindex) {
      let spocarr = [];
      this.tableHeaders.forEach((header, index) => {
        if (index > 2) {
          spocarr.push(header);
        }
      });
      return spocarr[spocindex].header;
    },
     convertType(type) {
      let newtype = "";
      newtype = type.toLowerCase();
      return newtype;
    },
    preFlightFetch(level, placement, type) {
      if (type === "Allocated" || type === "Delays") {
        let newtype = this.convertType(type);
        let spocname = this.mapSearchSpoc(placement);
        // this.fetchSelectedData(level, spocname, newtype);
        let spoc_id = "";
        for (let i = 0; i < this.macc_details.length; i++) {
            const element = this.macc_details[i];
            if (element.sub_team == "MAcc" && element.full_name == spocname) {
              spoc_id = element.id
            }
        }
        console.log("spoc_ids",spoc_id)
        this.fetchSelectedData(level, spocname,spoc_id, newtype);
        console.log("details 1",newtype,spocname,level)
      } else {
        this.fetchSelectedData(level, placement,this.macc_id.join(), type);
         console.log("details 2",level, placement, type)
      }
    },
    fetchSelectedData(level, placement,spoc_id, type) {
      // console.log(placement)
      this.$vs.loading();
      let popupName = `${level} - ${placement.toUpperCase()} - ${type.toUpperCase()}`;

      let url = `${constants.MILES_GOOGLE_INVITE}getExedDelaysbyLevelContactDetails`;
      console.log(placement)
        let obj = {
          selected_date: this.datatoTimestamp(this.dateRange),
          level: level,
          type: type,
          spoc_id: spoc_id,
        };
        axios
          .get(url, {
            params:obj,
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
          })
          .then(response => {
            response.data.data.forEach(lead => {
              lead.engagements = [];
              lead.mobile = [];
              lead.email = [];
            });
            // this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
            this.$vs.loading.close();
            let popupcount = response.data.total;
            console.log("counnt", popupcount);
            EventBus.$emit("open-delays-MAcc", [
              obj,
              url,
              response.data,
              "getExedDelaysbyLevelContactDetailsTest",
              popupName + "-" + popupcount
            ]);
          })
          .catch(error => {
            this.handleError(error);
          });
    },
  },
};
</script>

<style>
</style>